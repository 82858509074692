import React from 'react';
import NumberFormat from 'react-number-format';

const CustomPhoneInput = ({ value, onChange = () => {}, className }) => {
  const handleChange = (values) => {
    const newValue = `7${values.value}`;
    onChange(newValue);
  };
  return (
    <NumberFormat
      className={className}
      format="+7 (###) ###-####"
      placeholder="+7 (999) 999-99-99"
      mask="_"
      value={value?.slice(1)}
      allowEmptyFormatting={false}
      onValueChange={handleChange}
    />
  );
};

export default CustomPhoneInput;
