import { createSlice } from "@reduxjs/toolkit";
import { VIDEO_CONFIG } from "../../config/VideoConfig";
import { fetchScriptByPin } from "../actions/AccessData";
import { changeSelectedScriptData } from "../actions/ScriptsData";

const initVideo = { ...VIDEO_CONFIG };

export const videoSlice = createSlice({
  name: "video",
  initialState: initVideo,
  reducers: {
    setFound: (state, action) => {
      state.found = action.payload;
    },
    setPause: (state, action) => {
      state.pause = action.payload;
    },
    changePause: (state) => {
      state.pause = !state.pause;
    },
    setWebArIsReady: (state, action) => {
      state.isLoading = false;
    },
    setFocus: (state, action) => {
      state.focus = action.payload;
    },
    setCurrentVideo: (state, action) => {
      state.currentVideo = action.payload;
    },
    showVideo: (state, action) => {
      state.showVideoStatus = true;
    },
    hideVideo: (state, action) => {
      state.showVideoStatus = false;
    },
    showDoubleVideo: (state, action) => {
      state.showDoubleVideoStatus = true;
    },
    hideDoubleVideo: (state, action) => {
      state.showDoubleVideoStatus = false;
    },
    setARIsReady: (state, action) => {
      state.arReady = true;
    },
    setFirstStart: (state, action) => {
      state.firstStart = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchScriptByPin.fulfilled, (state, action) => {
        const isEducation = action.meta.arg.isEducation;
        state.currentVideo = isEducation
          ? action.payload.script.lessons[0]
          : action.payload.script.videos[0];
      })
      .addCase(changeSelectedScriptData.fulfilled, (state, action) => {
        state.currentVideo = action.payload.video;
      });
  },
});

export const {
  setFound,
  setPause,
  changePause,
  setWebArIsReady,
  setFocus,
  setCurrentVideo,
  showVideo,
  hideVideo,
  showDoubleVideo,
  hideDoubleVideo,
  setARIsReady,
  setFirstStart,
} = videoSlice.actions;

export default videoSlice.reducer;
