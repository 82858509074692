import styledComponents from 'styled-components';

export const InputWrapper = styledComponents.div`
display:flex;
flex-direction:column;
align-items:center;
gap:8px;
`;

export const Label = styledComponents.label`
display:none;
`;

export const Input = styledComponents.input`
box-sizing: border-box;
border: none;
height:60px;
width:288px;
padding:21px;
border-radius: 20px;
background:${(props) => props.background || '#F3F3F3'};

:focus {
  outline: none !important;
  border: none !important;
}
`;

export const Helper = styledComponents.div`
max-width: ${(props) => (props.accessIsDeclined ? '250px' : '200px')};

color:${(props) =>
  props.accessIsDeclined ? '#FF0000' : props.color || '#918E9B'};
font-weight: 400;
font-size: 13px;
line-height: 16px;
letter-spacing: -0.408px;
text-align: center;
`;
