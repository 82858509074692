/* eslint-disable react-hooks/exhaustive-deps */
import message from "antd-message";
import LessonsMenu from "components/LessonsMenu/LessonsMenu";
import { LESSON_ACCESS } from "constants/LocalStorageConstants";
import { getLessonsActiveList } from "helpers/checkLessonsActive";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setLessons } from "redux/reducers/Lesson.reducer";
import { selectHdr } from "redux/selectors/AccessData";
import { getAllLessons, getLessonById } from "redux/selectors/Lesson.selector";
import { selectMarkData, selectScriptId } from "redux/selectors/Scripts";
import LocalStorageService from "services/LocalStorageService";
import LoadingHOC from "../../../components/hoc/LoadingHOC";
import { GLTFLoader } from "../../../modules/GLTFLoader.js";
import { RGBELoader } from "../../../modules/RGBELoader.js";
import Scanner from "../Scanner";

const mock_envMap =
  "https://raw.githubusercontent.com/Solexofficial/Solexofficial/main/HDR23.hdr";
const mock_mark = "/../targets.mind";
const mock_model = "../../gltfModel/re4.gltf";

const EducationPage = () => {
  const [isMenuOpen, setShowMenu] = useState(true);
  const dispatch = useDispatch();

  const handleToggleShowMenu = useCallback(() => {
    setShowMenu((prev) => !prev);
  }, []);

  const { lessonId } = useParams();
  const educationId = useSelector(selectScriptId);
  const { mark, image } = useSelector(selectMarkData);
  const hdr = useSelector(selectHdr);
  const currentLesson = useSelector(getLessonById(lessonId));
  const lessonsList = useSelector(getAllLessons);
  const mindarRef = useRef();

  useEffect(() => {
    const accessData = LocalStorageService.get(LESSON_ACCESS) || [];
    const isAlreadyStarted = accessData.some((data) => data.id === educationId);

    if (!isAlreadyStarted) {
      const currentDate = new Date();
      currentDate.setHours("00", "00", "00", "00");

      const currentDateInMs = currentDate.getTime();

      const newData = [
        ...accessData,
        { id: educationId, startDate: currentDateInMs },
      ];
      LocalStorageService.set(LESSON_ACCESS, newData);
    }

    const activatedLessons = getLessonsActiveList(educationId, lessonsList);
    dispatch(setLessons(activatedLessons));
  }, []);

  const destroyAframe = () => {
    const mindArOverlays = document.querySelectorAll(".mindar-ui-overlay");
    [...mindArOverlays].forEach((overlay) => overlay.remove());

    const container = document.querySelector("#container");
    if (container) {
      container.remove();
    }
  };

  const removeAframeScripts = () => {
    const scripts = document.querySelectorAll("script");
    [...scripts]
      .slice(1, scripts.length - 1)
      .forEach((script) => script.remove());
  };

  useEffect(() => {
    removeAframeScripts();

    return () => {
      destroyAframe();
    };
  }, []);

  function init() {
    const hasContainer = document.querySelector("#container");
    if (!hasContainer) {
      const root = document.querySelector("main");
      const container = document.createElement("div");
      container.setAttribute("id", "container");
      container.style.width = "100vw";
      container.style.height = "100vh";
      container.style.position = "absolute";
      root.append(container);
    }

    var THREE = window.MINDAR.IMAGE.THREE;

    var mindarThree = new window.MINDAR.IMAGE.MindARThree({
      container: document.querySelector("#container"),
      imageTargetSrc: mark,
      // imageTargetSrc: mock_mark,
      filterMinCF: 1,
      filterBeta: 1000,
      missTolerance: 0,
      uiScanning: "#scanning-overlay",
      warmupTolerance: 0,
    });

    console.log("MINDAR THREE", mindarThree);

    const { renderer, scene, camera } = mindarThree;

    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.antialiasing = true;
    renderer.physicallyCorrectLights = true;
    renderer.colorManagement = true;

    var mixer, model, envmap, lights;
    const anchor = mindarThree.addAnchor(0);

    function loadModel() {
      var loader = new GLTFLoader();
      loader.load(
        currentLesson.model_3d,
        // mock_model,
        function (gltf) {
          anchor.group.add(gltf.scene);
          model = gltf.scene;
          // gltf.scene.scale.set(0.04, 0.04, 0.04);
          gltf.scene.scale.set(0.5, 0.5, 0.5);
          gltf.scene.position.set(0, 0, 0.02);
          gltf.scene.rotation.set(1.5, 0, 0);
          // gltf.scene.rotation.set(0, 1, 0);

          gltf.scene.traverse((node) => {
            if (node.material) {
              const defaultParams = {
                roughness: 1,
                metalness: 0,
                exposure: 1.0,
                envMapIntensity: 1.52,
                clearCoat: 0,
                clearCoatRoughness: 1,
              };
              node.material.name = node.material.name || "noName";

              node.material.roughness = defaultParams.roughness;
              node.material.metalness = defaultParams.metalness;
              node.material.exposure = defaultParams.exposure;
              node.material.envMapIntensity = defaultParams.envMapIntensity;
              node.material.clearCoat = defaultParams.clearCoat;
              node.material.clearCoatRoughness =
                defaultParams.clearCoatRoughness;
            }
          });

          mixer = new THREE.AnimationMixer(gltf.scene);

          gltf.animations.forEach((clip) => {
            mixer.clipAction(clip).play();
          });
        },
        // called while loading is progressing
        function (xhr) {
          console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
        },
        // called when loading has errors
        function (error) {
          console.log(error);
          message.error("Не удалось загрузить модель!", 10000);
        }
      );
    }

    const validHdrPath = "/" + hdr.split("/").slice(3).join("/");

    //ENV SCENE HDR
    function setEnvironment() {
      const loader = new RGBELoader();
      const pmremGenerator = new THREE.PMREMGenerator(renderer);
      pmremGenerator.compileEquirectangularShader();

      loader.load(
        validHdrPath,
        // mock_envMap,
        (texture) => {
          const envMap = pmremGenerator.fromEquirectangular(texture).texture;
          pmremGenerator.dispose();

          texture.mapping = THREE.EquirectangularReflectionMapping;

          scene.environment = envMap;
        },
        undefined,
        (err) => {
          message.error("Не удалось загрузить HDR окружение!", 10000);
          console.error(err);
        }
      );
    }

    //animation
    var clock = new THREE.Clock();
    renderer.toneMapping = THREE.LinearToneMapping;

    function animate() {
      requestAnimationFrame(animate);

      var delta = clock.getDelta();

      if (mixer) mixer.update(delta);

      renderer.render(scene, camera);
    }

    const start = async () => {
      try {
        await mindarThree.start();
        mindarRef.current = mindarThree;
        renderer.setAnimationLoop(() => {
          renderer.render(scene, camera);
        });
      } catch (error) {
        console.log("ERROR", error);
      }
    };

    if (currentLesson?.model_3d) {
      loadModel();
    } else {
      message.error(
        "Не удалось загрузить модель, пожалуйста обратитесь к своему менеджеру!"
      );
    }

    setEnvironment();
    start();
    animate();
  }

  useEffect(() => {
    destroyAframe();
    init();
  }, [lessonId]);

  return (
    <LoadingHOC loading={false}>
      <LessonsMenu open={isMenuOpen} onToggle={handleToggleShowMenu} />
      <Scanner currentVideo={currentLesson} hide={isMenuOpen} />
    </LoadingHOC>
  );
};

export default EducationPage;
