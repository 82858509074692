import { createSlice } from "@reduxjs/toolkit";
import { SCRIPTS_CONFIG } from "../../config/ScriptsConfig";
import { fetchScriptByPin } from "../actions/AccessData";
import { changeSelectedScriptData } from "../actions/ScriptsData";

const initScriptsData = { ...SCRIPTS_CONFIG };

export const scriptsSlice = createSlice({
  name: "scriptsData",
  initialState: initScriptsData,
  reducers: {
    setSelectedScript: (state, action) => {
      state.selectedScript = action.payload;
    },
    setPollIsCompleteById: (state, action) => {
      state.videos.find((video) => video.id === action.payload).pollIsComplete = true;
    },
    setVideos: (state, action) => {
      state.videos = action.payload;
    },
    setIsPreloaded: (state, action) => {
      state.isPreloaded = action.payload;
    },
    setPreloadedUrls: (state, action) => {
      state.preloadedUrls = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchScriptByPin.fulfilled, (state, action) => {
        const { mark, parameters, token, videos, id, models_3d, type } = action.payload.script;

        return {
          ...state,
          ...parameters,
          id,
          markData: mark,
          videos: type === "3d_model" ? models_3d : videos,
          token,
          models_3d,
          type,
        };
      })
      .addCase(changeSelectedScriptData.fulfilled, (state, action) => {
        state.selectedScript = action.payload.index;
      });
  },
});

export const {
  setSelectedScript,
  setPollIsCompleteById,
  setIsPreloaded,
  setPreloadedUrls,
  setVideos,
} = scriptsSlice.actions;

export default scriptsSlice.reducer;
