import { createAsyncThunk } from "@reduxjs/toolkit";
import { setPollChoiseIsSending } from "redux/reducers/Analytics";
import { selectCurrentVideoId, selectToken, selectVideoType } from "redux/selectors/Scripts";
import AnaslyticsService from "services/AnalyticsService";

export const sendPollChoise = createAsyncThunk(
  "analytics/sendPollChoise",
  async ({ videoId, choiseId }, thunkAPI) => {
    await thunkAPI.dispatch(setPollChoiseIsSending());

    const videoType = selectVideoType(thunkAPI.getState());
    const token = selectToken(thunkAPI.getState());

    const data = {
      video: videoId,
      choice: choiseId,
    };

    if (videoType === "3d_model") {
      delete data.video;
      data.model_3d = videoId;
    }

    console.log("SEND", data);
    // if (videoType === "3d_model") {
    //   delete data.video;
    //   data.model_3d = videoId;
    // }

    await AnaslyticsService.postPollChoise(data, token);
    return;
  }
);

export const sendViewAnalytics = createAsyncThunk(
  "analytics/sendViewAnalytics",
  async ({ time, isViewed }, thunkAPI) => {
    const token = selectToken(thunkAPI.getState());
    const videoId = selectCurrentVideoId(thunkAPI.getState());

    const data = {
      viewing_time: time,
      is_viewed: isViewed,
    };

    await AnaslyticsService.postVideoView(videoId, data, token);
    return;
  }
);
