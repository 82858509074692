import React from "react";
import styledComponents from "styled-components";

const Scanner = ({ currentVideo, hide }) => {
  return (
    <ScanningOverlayWrapper className="hidden" hide={currentVideo?.without_mark || hide}>
      <ScannerContainer id="scanning-overlay" className="hidden">
        <Text>Наведите камеру на метку</Text>
        <Scaner> </Scaner>
      </ScannerContainer>
    </ScanningOverlayWrapper>
  );
};

export default Scanner;

const ScanningOverlayWrapper = styledComponents.div`
position: absolute;
width:100%;
height:100%;
z-index:100;

display:flex;
flex-direction: column;
align-items:center;
justify-content:center;
pointer-events:none;
visibility:${(props) => (props.hide ? "hidden" : "visible")};

.hidden {
  display: none;
}


`;
const Text = styledComponents.div`
height:21px;
text-align:center;
font-weight: 500;
font-size: 17px;
line-height: 21px;
color: #FFFFFF;
z-index:500;
`;
const ScannerContainer = styledComponents.div`
display:flex;
flex-direction: column;
align-items:center;
justify-content:center;
gap:32px;
`;
const Scaner = styledComponents.div`
width:40vh;
height:40vh;
background-color: transparent;
border-radius:30px;
backdrop-filter: none;
box-shadow: 0 0 0 150vh rgba(0, 0, 0, 0.5);
`;
