import { getParametersFromUrl } from "helpers/functions/native-functions";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchScriptByPin } from "redux/actions/AccessData";
import AccessFormPage from "./AccessFormPage";
import AccessReferralPage from "./AccessReferralPage";

const AccessPageSwitcher = () => {
  const dispatch = useDispatch();

  const { id, referralToken, isEducation } = getParametersFromUrl();

  useEffect(() => {
    if (referralToken) {
      dispatch(fetchScriptByPin({ id, referralToken, isEducation }));
    }
    // eslint-disable-next-line
  }, [referralToken]);
  return <>{referralToken ? <AccessReferralPage /> : <AccessFormPage />}</>;
};

export default AccessPageSwitcher;
