import fetch from 'auth/ClientFetchInterceptor';

const SettingsService = {};

SettingsService.getLogo = function () {
  return fetch({
    url: '/admin/logo/',
    method: 'get',
  });
};
SettingsService.getDecoration = function () {
  return fetch({
    url: '/admin/decoration/',
    method: 'get',
  });
};

export default SettingsService;
