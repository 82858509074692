import message from 'antd-message';
import axios from 'axios';
import {
  API_BASE_URL,
  AUTH_TOKEN,
  TOKEN_PAYLOAD_KEY,
} from '../constants/ApiConstants';

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// Config

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const token = JSON.parse(sessionStorage.getItem(AUTH_TOKEN));

    if (token) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Token ${token}`;
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    console.log(error);
    message.error(error);

    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(error);
    message.error(error.response.data.error);

    if (error.response.status === 400 || error.response.status === 403) {
      const errData = error.response.data;
      console.log(errData);
      let errArray = [];

      for (let key in errData) {
        errArray.push({ key, value: errData[key] });
      }

      // errArray.forEach((err) => message.error(`${err.key}  ${err.value}`));
      errArray.forEach((err) => message.error(`${err.value}`));
    }

    if (error.response.status === 404) {
      message.error('Не найдено');
    }

    if (error.response.status === 500) {
      message.error('Ошибка сервера');
    }

    if (error.response.status === 508) {
      message.error('Время на ответ сервера истекло');
    }

    return Promise.reject(error);
  }
);

export default service;
