import PropTypes from "prop-types";
import React from "react";
import ReactCodeInput from "react-code-input";
import styledComponents from "styled-components";
import { Helper } from "../../styles/form-styles";

const PinCodeInput = ({ pin, setPin, decoration, accessIsDeclined }) => {
  const inputStyle = {
    MozAppearance: "number",
    maxWidth: "60px",
    width: "60px",
    borderRadius: "20px",
    fontSize: "24px",
    fontWeight: "700",
    height: "60px",
    textAlign: "center",
    padding: "0",
    backgroundColor: accessIsDeclined ? "#FFE6E6" : decoration.backdrop || "#F3F3F3",
    color: accessIsDeclined ? "#FF0000" : decoration.major || "#000000",
    border: "none",
  };

  return (
    <PinCodeWrapper>
      <ReactCodeInput
        value={pin}
        onChange={(value) => setPin(value)}
        fields={4}
        inputStyle={inputStyle}
        type="tel"
        inputMode="tel"
        pattern="\d*"
      />
      <Helper color={decoration.inactive} accessIsDeclined={accessIsDeclined}>
        {accessIsDeclined
          ? "Вы неверно ввели код, уточните ваш код доступа у менеджера"
          : "Введите код доступа, который вам сообщил менеджер*"}
      </Helper>
    </PinCodeWrapper>
  );
};

export default PinCodeInput;

const PinCodeWrapper = styledComponents.div`
display:flex;
flex-direction:column;
align-items:center;
gap:8px;

.react-code-input { 
  display:flex !important;
  gap:16px;
  caret-color: #007AFF;

  input:focus-visible {
    outline: none !important;
    border: none !important;
  }

  input:focus {
    outline: none !important;
    border: none !important;
  }

  input::selection {
    // background: #007AFF;
  }
}
`;

PinCodeInput.propTypes = {
  decoration: PropTypes.shape({
    backdrop: PropTypes.string,
    inactive: PropTypes.string,
    major: PropTypes.string,
  }),
  pin: PropTypes.string,
  setPin: PropTypes.func,
};
