import PropTypes from 'prop-types';
import React from 'react';
import styledComponents from 'styled-components';
import Spinner from '../Spinner';

const LoadingHOC = ({ children, loading }) => {
  return (
    <>
      {loading && (
        <Flex
          alignItems="center"
          justifyContent="center"
          className="loading-hoc">
          <Spinner />
        </Flex>
      )}
      <>{children}</>
    </>
  );
};

export default LoadingHOC;

const Flex = styledComponents.div`
position:absolute;
z-index:50;
background: #fff;

width:100%;
height:100%;

display:flex;
align-items:center;
justify-content:center;
`;

LoadingHOC.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
};
