import { createSlice } from "@reduxjs/toolkit";
import { LESSON_CONFIG } from "config/LessonConfig";
import { fetchScriptByPin } from "redux/actions/AccessData";

const initLesson = { ...LESSON_CONFIG };

export const lessonSlice = createSlice({
  name: "lesson",
  initialState: initLesson,
  reducers: {
    setCurrentLesson: (state, action) => {
      state.currentLessonId = action.payload;
    },
    setLessons: (state, action) => {
      state.entities = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScriptByPin.fulfilled, (state, action) => {
      const { lessons } = action.payload.script;
      if (lessons) {
        const transformLessons = lessons
          .sort((a, b) => {
            if (a.for_specialist || b.for_specialist) return 1;
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 0;
          })
          .map((lesson, idx) => ({ ...lesson, id: idx + 1 }));
        return {
          ...state,
          entities: transformLessons,
          loading: false,
        };
      }
      return { ...state };
    });
    builder.addCase(fetchScriptByPin.rejected, (state, action) => {
      return {
        ...state,
        error: action.payload?.message,
      };
    });
  },
});

const { actions, reducer: LessonReducer } = lessonSlice;

export const { setCurrentLesson, setLessons } = actions;

export default LessonReducer;
