export const ACCESS_STATUSES = {
  allowed: "allowed",
  declined: "declined",
};

export const ACCESS_PIN_CODE = {
  doctor: "1111",
};

// export const FILTER_MIN_CF = 0.00004;
export const FILTER_MIN_CF = 0.0001;

// export const FILTER_BETA = 0.00007;
export const FILTER_BETA = 0.001;

export const INTERPOLATION_FACTOR = 5;

export const WARMUP_TOLERANCE = 3;

export const MISS_TOLERANCE = 0;

export const DOUBLE_VIDEO_MASK = {
  top: "top",
  bottom: "bottom",
  left: "left",
  right: "right",
};
