import PropTypes from 'prop-types';
import React from 'react';
import styledComponents from 'styled-components';
import { Helper, InputWrapper, Label } from '../../styles/form-styles';
import CustomPhoneInput from '../../components/CustomPhoneInput';

const PhoneInput = ({ phone, setPhone, decoration }) => {
  return (
    <InputWrapper>
      <Label htmlFor="phone">Phone</Label>
      <StyledPhoneInput
        value={phone}
        onChange={(value) => setPhone(value)}
        background={decoration.backdrop}
      />

      <Helper color={decoration.inactive}>Укажите ваш телефон*</Helper>
    </InputWrapper>
  );
};

export default PhoneInput;

const StyledPhoneInput = styledComponents(CustomPhoneInput)`
box-sizing: border-box;
border: none;
height:60px;
width:288px;
padding:21px;
border-radius: 20px;
background:${(props) => props.background || '#F3F3F3'};

:focus {
  outline: none !important;
  border: none !important;
}
`;

PhoneInput.propTypes = {
  decoration: PropTypes.shape({
    backdrop: PropTypes.any,
    inactive: PropTypes.any,
  }),
  phone: PropTypes.string,
  setPhone: PropTypes.func,
};
