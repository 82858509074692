import PropTypes from 'prop-types';
import React from 'react';
import { Helper, Input, InputWrapper, Label } from '../../styles/form-styles';

const NameInput = ({ name, setName, decoration }) => {
  return (
    <InputWrapper>
      <Label htmlFor="name">Name</Label>
      <Input
        id="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="ФИО"
        background={decoration.backdrop}
      />
      <Helper color={decoration.inactive}>Укажите ваше ФИО*</Helper>
    </InputWrapper>
  );
};

export default NameInput;

NameInput.propTypes = {
  decoration: PropTypes.shape({
    backdrop: PropTypes.any,
    inactive: PropTypes.any,
  }),
  name: PropTypes.string,
  setName: PropTypes.func,
};
