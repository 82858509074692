import { createSelector } from "@reduxjs/toolkit";

export const getAllLessons = (state) => state.lesson.entities;
export const getLessonsLoadingStatus = (state) => state.lesson.loading;
export const getCurrentLessonId = (state) => state.lesson.currentLessonId;
export const getLessonById = (id) => (state) =>
  state.lesson.entities.find((lesson) => +lesson.id === +id);
export const getCurrentLessonData = createSelector(
  [getAllLessons, getCurrentLessonId],
  (lessons, currentLessonId) => {
    return lessons.find((lesson) => lesson.id === currentLessonId);
  }
);
export const getIdLessonForSpecialist = (state) =>
  state.lesson.entities.find((lesson) => lesson.for_specialist).id;
