import message from "antd-message";

import PinCodeInput from "pages/AccessFormPage/PinCodeInput";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setCurrentLesson } from "redux/reducers/Lesson.reducer";
import { selectAccessFormData } from "redux/selectors/AccessData";
import { getAllLessons, getIdLessonForSpecialist } from "redux/selectors/Lesson.selector";
import { selectAccessPinCode } from "redux/selectors/Scripts";
import { Button } from "styles/constols-styles";
import {
  ButtonsRow,
  LessonsListItem,
  LessonsListWrapper,
  PinCodeWrapper,
} from "styles/lessonMenu-styles";
import LessonsItem from "./LessonsItem";

const LessonsList = ({ open, onToggleMenu }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentPath = history.location.pathname;
  const path = currentPath.slice(1, currentPath.lastIndexOf("/"));

  const [isShowPin, setShowPin] = useState(false);
  const [accessIsDeclined, setAccessIsDeclined] = useState(false);
  const [pin, setPin] = useState("");
  const ACCESS_PIN_CODE = useSelector(selectAccessPinCode);

  const { decoration } = useSelector(selectAccessFormData);

  const lessonsList = useSelector(getAllLessons);
  const specialistId = useSelector(getIdLessonForSpecialist);
  const lessons = lessonsList.filter((lesson) => !lesson.for_specialist);

  useEffect(() => {
    setAccessIsDeclined(false);
  }, [pin]);

  const handlePinCodeSend = () => {
    if (pin !== ACCESS_PIN_CODE) {
      message.error("Отказно в доступе!");
      return setAccessIsDeclined(true);
    }
    message.success("Доступ разрешён!");
    onToggleMenu();
    setPin("");
    setShowPin(false);
    history.push(`/${path}/${specialistId}`);
  };

  const handleClickMenuItem = ({ id, isActive }) => {
    if (isActive) {
      dispatch(setCurrentLesson(id));
      history.push(`/${path}/${id}`);
      onToggleMenu();
    } else {
      message.error("Урок не доступен!");
    }
  };

  return (
    <LessonsListWrapper open={open}>
      {lessons.map((lesson, idx) => (
        <LessonsListItem
          key={lesson.id}
          onClick={() => handleClickMenuItem(lesson)}
          isActive={lesson.isActive}
        >
          <LessonsItem
            title={lesson.name || `Урок ${idx + 1}`}
            isActive={lesson.isActive}
            onToggleMenu={onToggleMenu}
          />
        </LessonsListItem>
      ))}
      <LessonsListItem onClick={() => setShowPin((prev) => !prev)} forExpert>
        <LessonsItem title="Материал для специалиста" />
      </LessonsListItem>

      {isShowPin && (
        <PinCodeWrapper>
          <PinCodeInput
            pin={pin}
            setPin={setPin}
            decoration={decoration}
            accessIsDeclined={accessIsDeclined}
          />

          <ButtonsRow>
            <Button onClick={handlePinCodeSend}>
              <span>Подтвердить</span>
            </Button>
            <Button onClick={() => setShowPin(false)}>
              <span>Отменить</span>
            </Button>
          </ButtonsRow>
        </PinCodeWrapper>
      )}
    </LessonsListWrapper>
  );
};

export default LessonsList;
