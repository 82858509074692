import { createSlice } from '@reduxjs/toolkit';
import { ANALYTICS_CONFIG } from 'config/Analytics';
import { sendPollChoise } from 'redux/actions/Analytics';

const initAnalytics = { ...ANALYTICS_CONFIG };

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: initAnalytics,
  reducers: {
    setPollChoiseIsSending: (state, action) => {
      state.pollChoiseIsSending = true;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(sendPollChoise.fulfilled, (state, action) => {
      state.pollChoiseIsSending = false;
    });
  },
});

export const { setPollChoiseIsSending } = analyticsSlice.actions;

export default analyticsSlice.reducer;
