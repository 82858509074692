import styled from "styled-components";

export const MenuWrapper = styled.div`
  margin-top: 32px;
  position: relative;
  top: 0;
  left: 50%;
  z-index: 10;
  min-width: 343px;
  /* display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center; */
  overflow: auto;

  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: all 0.2s linear;

  transform: translateX(50%);
  transform: ${({ open }) => (open ? "translate(-50%, 0)" : "translate(-50%, 150%)")};
`;

export const PinCodeWrapper = styled.div`
  border-radius: 20px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
`;

export const LessonsListWrapper = styled.ul`
  box-sizing: border-box;
  counter-reset: new 0;
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 4px;
  margin: 0;
  margin-bottom: 200px;
`;

export const LessonsListItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  /* color: #fff; */
  color: ${({ isActive, forExpert }) =>
    isActive || forExpert ? "#fff" : "rgba(255,255,255, 0.5)"};

  cursor: pointer;
  min-height: 76px;
  border-radius: 20px;
  background: ${({ isActive, forExpert }) =>
    isActive ? "#191e43" : forExpert ? "#1B3452" : "#0B0D1F"};
  counter-increment: new 1;

  &::after {
    /* content: counter(new); */
    content: counter(new, decimal-leading-zero) " ";
    font-size: 20px;
    font-weight: 700px;
    font-family: inherit;
    position: absolute;
    top: 50%;
    left: 24px;
    z-index: 2;
    color: #fff;
    opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};

    transform: translateY(-50%);
  }

  &:last-child {
    &::after {
      content: none;
    }
  }
`;

export const LessonListTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
`;

export const Lesson = styled.div`
  display: block;
  height: 100%;
`;

export const ButtonsRow = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: "5px";
`;

export const LessonBtn = styled.button`
  pointer-events: none;
  height: 100%;
  padding: 0;
  padding-left: 80px;
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 16px;
  font-family: inherit;
  color: inherit;
`;
