export const getParametersFromUrl = () => {
  const pathnameArr = window.location.pathname.split("/");
  const isEducation = pathnameArr.includes("e");
  const id = pathnameArr[pathnameArr.length - 1]
    ? pathnameArr[pathnameArr.length - 1]
    : pathnameArr[pathnameArr.length - 2];

  const referralToken = window.location.search.split("?referral=")[1];

  return { id, referralToken, isEducation };
};

export const formIsCompleted = (pin, name, phone, parameters) => {
  const pinIsCompleted = pin.length === 4;
  const nameIsCompleted = name.length > 0 || !parameters.name;
  const phoneIsCompleted = phone.length === 11 || !parameters.phone;

  const isCompleted = pinIsCompleted && nameIsCompleted && phoneIsCompleted;

  return isCompleted;
};

export function hexToRgbA(hex, alfa) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + `,${alfa})`;
  }
  throw new Error("Bad Hex");
}

export function isNumeric(str) {
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
}

export function throttle(callee, timeout) {
  let timer = null;

  return function perform(...args) {
    if (timer) return;
    timer = setTimeout(() => {
      callee(...args);
      clearTimeout(timer);
      timer = null;
    }, timeout);
  };
}
