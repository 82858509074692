import { createSelector } from "@reduxjs/toolkit";

export const selectMarkData = (state) => state.scriptsData?.markData;
export const selectVideoType = (state) => state.scriptsData?.type;

export const selectVideoScripts = (state) => state.scriptsData?.videos;
export const selectModelsScripts = (state) => state.scriptsData?.models_3d;

export const selectIsViewWithoutMark = (state) => state.scriptsData?.without_mark;

export const selectVideos = createSelector(selectVideoScripts, (videos) =>
  videos.map((video) => video)
);

export const selectQuestions = createSelector(selectVideoScripts, (scripts) =>
  scripts.map(({ id, question, answers, without_mark }) => ({
    id,
    question,
    answers,
    without_mark,
  }))
);

export const selectPoll = createSelector(selectVideoScripts, (scripts) =>
  scripts.map(({ id, poll_on, poll, poll_choices }) => ({
    id,
    poll_on,
    poll,
    poll_choices,
  }))
);

export const selectSelectedScript = (state) => state.scriptsData?.selectedScript;

export const selectToken = (state) => state.scriptsData?.token;
export const selectMarkCount = (state) => state.scriptsData?.markData?.images.length;

export const selectCurrentVideoId = createSelector(
  selectSelectedScript,
  selectVideos,
  (index, videos) => videos[index].id
);
export const selectPollCompleteStatus = createSelector(
  [selectSelectedScript, selectVideoScripts],
  (index, videos) => (videos[index].pollIsComplete ? true : false)
);

export const selectCurrentPoll = createSelector(
  selectSelectedScript,
  selectPoll,
  (index, polls) => polls[index]
);

export const selectReferralStatus = (state) => state.scriptsData?.referral_program;
export const selectScriptId = (state) => state.scriptsData?.id;
export const selectAccessPinCode = (state) => state.scriptsData?.staff_pin_code;

export const selectPreloadedStatus = (state) => state.scriptsData?.isPreloaded;
export const selectPreloadedUrls = (state) => state.scriptsData?.preloadedUrls;
