import Switch from "components/Switch";
import { getParametersFromUrl } from "helpers/functions/native-functions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allowAccessStatus } from "redux/reducers/AccessData";
import { setIsPreloaded } from "redux/reducers/ScriptsData";
import { selectPreloadedStatus } from "redux/selectors/Scripts";
import styled from "styled-components";
import { ReactComponent as ArrowIcon } from "../../assets/svg/icons/buttons/right-arrow.svg";
import LoadingHOC from "../../components/hoc/LoadingHOC";
import { selectAccessFormData } from "../../redux/selectors/AccessData";

const AccessReferralPage = () => {
  const dispatch = useDispatch();
  const { logo, decoration } = useSelector(selectAccessFormData);
  const [imageIsLoad, setimageIsLoad] = useState(false);
  const handleLoadLogo = () => setimageIsLoad(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(allowAccessStatus());
  };

  const isPreloaded = useSelector(selectPreloadedStatus);
  const handleChange = () => {
    dispatch(setIsPreloaded(!isPreloaded));
  };

  const isAllLoading = logo && !imageIsLoad;
  const { isEducation } = getParametersFromUrl();

  return (
    <LoadingHOC loading={isAllLoading}>
      <FormWrapper onSubmit={handleSubmit} background={decoration.background}>
        {logo ? (
          <Logo src={logo} alt="Logo" onLoad={handleLoadLogo} onError={handleLoadLogo} />
        ) : (
          <LogoPlaceholder />
        )}

        <Title color={decoration.major}>Вы перешли по реферальной ссылке</Title>

        {!isEducation && (
          <div>
            <SwitchContainer>
              <span>Предзагрузить сюжет</span>
              <Switch
                name="preload"
                checked={isPreloaded}
                onChange={handleChange}
                decoration={decoration}
              />
            </SwitchContainer>
            <SwitchDescription color={decoration.inactive}>
              {/* В случае отсутствия предзагрузки видео могут наблюдаться задержки */}
              Рекомендуется при слабом интернет соединении
            </SwitchDescription>
          </div>
        )}

        <Button background={decoration.accent}>
          <span>Старт</span>
          <ArrowIcon />
        </Button>
      </FormWrapper>
    </LoadingHOC>
  );
};

export default AccessReferralPage;

const FormWrapper = styled.form`
  overflow: auto;
  width: 100%;
  height: 100%;

  padding: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  background: ${(props) => props.background || "#FFFFFF"};
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & span {
    font-weight: 700;
  }
`;

const SwitchDescription = styled.p`
  text-align: center;
  font-size: 13px;
  color: ${(props) => props.color || "#000000"};
`;

const Logo = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
`;

const Title = styled.div`
  max-width: 200px;

  color: ${(props) => props.color || "#000000"};

  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.408px;
  text-align: center;
`;

const Button = styled.button`
  position: fixed;
  bottom: 0px;
  margin-bottom: 10vh;

  box-sizing: border-box;
  border: none;
  outline: none;

  height: 53px;
  width: 155px;

  padding: 16px;
  border-radius: 20px;

  color: #ffffff;
  background: ${(props) => props.background || "#191E43"};

  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: -0.408px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  cursor: pointer;
`;
const LogoPlaceholder = styled.div`
  width: 80px;
  height: 30px;
`;
