import fetch from 'auth/ClientFetchInterceptor';

const ScriptService = {};

ScriptService.getScriptByPin = function (data) {
  return fetch({
    url: '/script/',
    method: 'post',
    data,
  });
};

ScriptService.getScriptByReferral = function (data, token) {
  return fetch({
    url: '/script/',
    method: 'post',
    data,
    headers: {
      AnalyticalToken: token,
    },
  });
};

ScriptService.setTokenByPin = function (data) {
  return fetch({
    url: '/analytical_token/',
    method: 'post',
    data,
  });
};

ScriptService.getScript = function () {
  return fetch({
    url: '/script/',
    method: 'get',
  });
};

ScriptService.checkScript = function (id) {
  return fetch({
    url: `/script/${id}/check`,
    method: 'get',
  });
};

export default ScriptService;
