import React from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { selectFavicon } from 'redux/selectors/AccessData';

const Favicon = () => {
  const favicon = useSelector(selectFavicon);
  const faviconHttps = 'https' + favicon?.split('http')[1];

  return createPortal(
    <>
      <link rel="icon" type="image/png" href={faviconHttps} />
      <link rel="apple-touch-icon" href={faviconHttps} />
    </>,
    document.querySelector('head')
  );
};

export default Favicon;
