import fetch from 'auth/ClientFetchInterceptor';

const AnaslyticsService = {};

AnaslyticsService.postPollChoise = function (data, token) {
  return fetch({
    url: '/poll/',
    method: 'post',
    data,
    headers: {
      AnalyticalToken: token,
    },
  });
};

AnaslyticsService.postVideoView = function (id, data, token) {
  return fetch({
    url: `/video/${id}/`,
    method: 'post',
    data,
    headers: {
      AnalyticalToken: token,
    },
  });
};

export default AnaslyticsService;
