import useWindowHeight from "hooks/useWindowHeight";
import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import styledComponents from "styled-components";
import { ACCESS_STATUSES } from "../constants/AppConstants";
import { selectAccessStatus } from "../redux/selectors/AccessData";
import AccessPageSwitcher from "./AccessPageSwitcher";
// import ArPage from './ArPage';
import LoadingHOC from "../components/hoc/LoadingHOC";
import { getParametersFromUrl } from "helpers/functions/native-functions";
import ArEducationPage from "./ArPage/ArModelPage/ArEducationPage";

const ArPage = lazy(() => import("./ArPage"));

const AppPage = () => {
  useWindowHeight();
  const { id, isEducation } = getParametersFromUrl();
  const accessStatus = useSelector(selectAccessStatus);

  const isAccessAllowed = accessStatus === ACCESS_STATUSES.allowed;
  return (
    <Main>
      {isAccessAllowed ? (
        <Suspense fallback={<LoadingHOC loading />}>
          {isEducation ? <ArEducationPage id={id} /> : <ArPage />}
        </Suspense>
      ) : (
        <AccessPageSwitcher />
      )}
    </Main>
  );
};

export default AppPage;

const Main = styledComponents.main`
position: relative;
height: calc(var(--vh, 1vh) * 100);
width: 100vw;
display:flex;
margin: 0px;
overflow: hidden;
`;
