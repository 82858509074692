export const usePlatform = () => {
  const userAgent = window.navigator.userAgent;

  const androidRe = /android/i;
  const iosRe = /iphone|ipad/i;

  if (androidRe.test(userAgent.toLowerCase())) {
    return 'android';
  }

  if (iosRe.test(userAgent.toLowerCase())) {
    return 'ios';
  }

  return 'desktop';
};
