export const VIDEO_CONFIG = {
  found: false,
  pause: false,
  isLoading: true,
  focus: false,
  currentVideo: null,
  showVideoStatus: false,
  arReady: false,
  firstStart: false,
  showDoubleVideoStatus: false,
};
