import BurgerButton from "components/BurgerButton";
import React, { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { getAllLessons } from "redux/selectors/Lesson.selector";
import { MenuWrapper } from "styles/lessonMenu-styles";
import LessonsList from "./LessonsList";

const LessonsMenu = ({ open, onToggle }) => {
  return (
    <>
      <BurgerButton open={open} onClick={onToggle} />
      <MenuWrapper open={open}>
        <LessonsList open={open} onToggleMenu={onToggle} />
      </MenuWrapper>
    </>
  );
};

export default LessonsMenu;
