import { createAsyncThunk } from '@reduxjs/toolkit';
import { setPause } from 'redux/reducers/Video';
import { selectCurrentVideo, selectFound } from 'redux/selectors/Video';
import { selectVideos } from '../selectors/Scripts';

export const changeSelectedScriptData = createAsyncThunk(
  'scriptData/changeSelectedScriptData',
  async (index, thunkAPI) => {
    const videos = selectVideos(thunkAPI.getState());
    const video = videos[index];

    const found = selectFound(thunkAPI.getState());
    if (!found) await thunkAPI.dispatch(setPause(true));

    return { index, video };
  }
);

export const checkMarkIsFound = createAsyncThunk(
  'scriptData/checkMarkIsFound',
  async (callback, thunkAPI) => {
    const found = selectFound(thunkAPI.getState());
    const currentVideo = selectCurrentVideo(thunkAPI.getState());

    if (found || currentVideo.without_mark) callback();
    else
      !currentVideo.without_mark && (await thunkAPI.dispatch(setPause(true)));
  }
);
