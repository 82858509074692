import { createSelector } from "@reduxjs/toolkit";
import { selectPreloadedStatus, selectVideos } from "./Scripts";

export const selectFound = (state) => state.video?.found;
export const selectPause = (state) => state.video?.pause;
export const selectFocus = (state) => state.video?.focus;
export const selectWebArIsLoading = (state) => state.video?.isLoading;
export const selectCurrentVideo = (state) => state.video?.currentVideo;
export const selectShowVideoStatus = (state) => state.video?.showVideoStatus;
export const selectIsArReady = (state) => state.video?.arReady;
export const selectFirstStart = (state) => state.video?.firstStart;
export const selectshowDoubleVideoStatus = (state) => state.video?.showDoubleVideoStatus;
