import styled from "styled-components";

const Switch = ({ name, checked, onChange, decoration }) => {
  const id = `switch_${name}`;
  return (
    <SwitchWrapper>
      <SwitchLabel>
        <SwitchInput
          type="checkbox"
          id={id}
          name={name}
          checked={checked}
          onChange={onChange}
          backgroundColor={decoration.major}
        />
        <SwitchSlider backgroundColor={decoration.backdrop} />
      </SwitchLabel>
    </SwitchWrapper>
  );
};

export default Switch;

const SwitchWrapper = styled.div`
  padding: 5px;
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.backgroundColor || "#333"};
  transition: 400ms;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    border-radius: 50%;
    background-color: ${(props) => props.backgroundColor || "#f5f5f5"};
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    transition: 400ms;
  }
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${SwitchSlider} {
    background-color: ${(props) => props.backgroundColor || "blue"};

    &:before {
      transform: translateX(26px);
    }
  }
`;
