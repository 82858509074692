// import 'aframe';
import LoadingHOC from "components/hoc/LoadingHOC";
import useLoadScripts from "hooks/useLoadScripts";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { checkScriptById, fetchLogos } from "redux/actions/AccessData";
import { setScriptIsNotExist } from "redux/reducers/AccessData";
import { selectScriptExistStatus } from "redux/selectors/AccessData";
import styledComponents from "styled-components";
import "./App.scss";
import { getParametersFromUrl, isNumeric } from "./helpers/functions/native-functions";
import { usePlatform } from "./hooks/usePlatform";
import AppPage from "./pages/AppPage";

function App() {
  useLoadScripts();
  window.arResizeOn = true;

  const dispatch = useDispatch();

  const platform = usePlatform();

  const isDesktop = platform === "desktop";
  // const isDesktop = false;
  const { id, isEducation } = getParametersFromUrl();
  const isScriptExist = useSelector(selectScriptExistStatus);

  useEffect(() => {
    dispatch(fetchLogos());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(checkScriptById({ id, isEducation }));
    }
    if (!id) dispatch(setScriptIsNotExist());
    // eslint-disable-next-line
  }, []);

  const isScriptExistNotChecked = isScriptExist === null;

  const showApp = id && !isDesktop && isScriptExist;
  const invalidLink = !id || !isNumeric(id);
  return (
    <LoadingHOC loading={isScriptExistNotChecked}>
      {!showApp && (
        <AttentionWrapper>
          {isDesktop && <Attention>Откройте приложение с телефона</Attention>}

          {invalidLink && <Attention>Введите верную ссылку</Attention>}
          {!isScriptExist && id && <Attention>Видеоконтент недоступен</Attention>}
        </AttentionWrapper>
      )}
      {showApp && (
        <BrowserRouter>
          <Switch>
            <Route path="/:id" component={AppPage} />
          </Switch>
        </BrowserRouter>
      )}
    </LoadingHOC>
  );
}

export default App;

const Attention = styledComponents.div`
font-weight: 700;
font-size: 24px;
line-height: 29px;
letter-spacing: -0.408px;
`;

const AttentionWrapper = styledComponents.article`
width: 100vw;
height: 100vh;
display:flex;
flex-direction: column;
align-items:center;
justify-content:center;
`;
