import React from "react";
import { Lesson, LessonBtn } from "styles/lessonMenu-styles";

const LessonsItem = ({ title, isActive, onToggleMenu }) => {
  return (
    <Lesson>
      <LessonBtn disabled={!isActive}>{title}</LessonBtn>
    </Lesson>
  );
};

export default React.memo(LessonsItem);
