export const ACCESS_DATA_CONFIG = {
  accessParameters: {
    pin: '',
    name: '',
    phone: '',
  },
  accessStatus: null,
  isScriptExist: null,
  favicon: '',
  formData: {
    logo: '',
    parameters: {
      name: false,
      phone: false,
    },
    decoration: {
      major: '#000000',
      accent: '#191E43',
      backdrop: '#F3F3F3',
      inactive: '#918E9B',
      background: '#FFFFFF',
    },
  },
  isLoading: false,
  isReferralAccessLoading: false,
};
