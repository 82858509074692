import { LESSON_ACCESS } from "constants/LocalStorageConstants";
import LocalStorageService from "services/LocalStorageService";

const COUNT_MS_IN_DAY = 86_400_000;

export const getLessonsActiveList = (educationId, lessons) => {
  const accessData = LocalStorageService.get(LESSON_ACCESS);
  const currentDate = Date.now();

  const { startDate } = accessData.find(
    (educationData) => educationData.id === educationId
  );

  const diffMs = Number(currentDate - startDate).toFixed();
  const MAX_ACTIVE_INDEX = Math.floor(diffMs / COUNT_MS_IN_DAY);

  const forSpecialistIndex = lessons.findIndex(
    (lesson) => lesson.for_specialist
  );

  const activatedLessons = lessons
    .filter((lesson) => !lesson.for_specialist)
    .map((lesson, idx) => {
      if (idx <= MAX_ACTIVE_INDEX) {
        return {
          ...lesson,
          isActive: true,
        };
      }
      return lesson;
    });

  return [...activatedLessons, lessons[forSpecialistIndex]];
};
