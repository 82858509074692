import { useEffect } from "react";

const useLoadScripts = () => {
  const loadScript = (src) => {
    let script = document.createElement("script");
    script.src = src;
    script.async = false;
    document.body.append(script);
  };

  useEffect(() => {
    loadScript("/libs/aframe.min.js?v1");
    loadScript("/libs/1.1.4/mindar-image.prod.js?v1");
    loadScript("/libs/1.1.4/mindar-image-aframe.prod.js?v1");
    loadScript("https://cdn.jsdelivr.net/npm/mind-ar@1.1.5/dist/mindar-image-three.prod.js");
    // eslint-disable-next-line
  }, []);
};

export default useLoadScripts;
