import fetch from "auth/ClientFetchInterceptor";

const EducationService = {};

EducationService.getEducationByPin = function (data) {
  return fetch({
    url: "/education/",
    method: "post",
    data,
  });
};

EducationService.getEducationByReferral = function (data, token) {
  return fetch({
    url: "/education/",
    method: "post",
    data,
    headers: {
      AnalyticalToken: token,
    },
  });
};

EducationService.setTokenByPin = function (data) {
  return fetch({
    url: "/analytical_token/",
    method: "post",
    data,
  });
};

EducationService.getEducation = function () {
  return fetch({
    url: "/education/",
    method: "get",
  });
};

EducationService.checkEducation = function (id) {
  return fetch({
    url: `/education/${id}/check`,
    method: "get",
  });
};

export default EducationService;
