import styledComponents from "styled-components";

export const Button = styledComponents.button`
box-sizing: border-box;
border: none;
outline: none;

height:53px;
min-width:155px;
max-width:343px;


padding:16px;
border-radius: 20px;

color:#FFFFFF;
background:${(props) => props.background || "#191E43"};

font-weight: 500;
font-size: 17px;
line-height: 21px;
letter-spacing: -0.408px;

display:flex;
align-items: center;
justify-content: center;
gap:16px;

cursor:pointer;
user-select:none;

z-index:400;
`;

export const Question = styledComponents.div`
box-sizing: border-box;
margin:0 16px;
width:calc(100% - 32px);
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 24px;
gap: 10px;

text-align:center;

background: rgba(0, 0, 0, 0.8);
backdrop-filter: blur(16px);
border-radius: 20px;

color:#FFFFFF;
font-weight: 500;
font-size: 17px;
line-height: 21px;
letter-spacing: -0.408px;

span{
word-wrap:break-word;
overflow:hidden;
}
`;

export const AnswersWrapper = styledComponents.div`
box-sizing: border-box;

padding:0 16px;
width: 100%;
// display: flex;
// flex-flow:  wrap;
display: grid;
grid-template-columns: 1fr 1fr;
column-gap:8px;
row-gap:8px;

`;

export const PollAnswersWrapper = styledComponents.div`
box-sizing: border-box;

padding:0 16px;

width: 100%;
display: flex;
flex-direction: column;
gap:8px;

`;

export const AnswerButton = styledComponents(Button)`
min-height:53px;
height:auto;
width: 100%;
display: flex;
grid-column:${(props) => (props.muchWords ? "span 2" : "span 1")};
min-width: ${(props) => (props.muchWords ? "unset" : "auto")};
max-width: ${(props) => (props.muchWords ? "unset" : "auto")};
// grid-column: span 2;

:nth-last-child(1):nth-child(odd){
  grid-column: span 2;
  min-width: unset;
  max-width: unset;
}

span{
  text-align: left;
  width:100%;
  word-wrap:break-word;
}
`;

export const PollAnswerButton = styledComponents(Button)`
width: 100%;
flex: 2;
min-width: unset;
max-width: unset;
backdrop-filter: blur(16px);

span{
  text-align: left;
  width:100%;
  word-wrap:break-word;
}
`;

export const ReferralButton = styledComponents(Button)`
position: fixed;
top:54px;
right:14px;

opacity:0.8;

min-width:52px;
min-height:52px;
width:52px;
height:52px;
border: 2px solid #000000;

svg{
 flex-shrink:0;
}

`;

export const FullscreenButton = styledComponents(Button)`
position: fixed;
top:110px;
right:14px;

opacity:0.8;

min-width:52px;
min-height:52px;
width:52px;
height:52px;
border: 2px solid #000000;

svg{
 flex-shrink:0;
}

`;

export const PauseButton = styledComponents(Button)`
position: fixed;
top:54px;
left:14px;

opacity:0.8;
pointer-events:${(props) => (props.disable ? "none" : "auto")};


min-width:52px;
min-height:52px;
width:52px;
height:52px;
border: 2px solid #000000;

svg{
 flex-shrink:0;
}

`;
