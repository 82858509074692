import { createAsyncThunk } from "@reduxjs/toolkit";
import { startReferralAccessing } from "redux/reducers/AccessData";
import EducationService from "services/EducationService";
// import { AUTH_TOKEN } from 'constants/ApiConstants';
import ScriptService from "../../services/ScriptService";
import SettingsService from "../../services/SettingsService";
import { selectAccessParameters } from "../selectors/AccessData";

export const checkScriptById = createAsyncThunk(
  "accessData/checkScriptById",
  async ({ id, isEducation }, thunkAPI) => {
    const isScriptExist = isEducation
      ? await EducationService.checkEducation(id)
      : await ScriptService.checkScript(id);
    return isScriptExist;
  }
);

export const fetchFormDataById = createAsyncThunk(
  "accessData/fetchFormDataById",
  async (id, thunkAPI) => {
    const decoration = await SettingsService.getDecoration();
    return { decoration };
  }
);

export const fetchLogos = createAsyncThunk("accessData/fetchLogos", async (id, thunkAPI) => {
  const { logo, favicon, hdr } = await SettingsService.getLogo();
  return { logo, favicon, hdr };
});

export const fetchScriptByPin = createAsyncThunk(
  "accessData/fetchScriptByPin",
  async ({ id, referralToken, isEducation }, thunkAPI) => {
    const parameters = selectAccessParameters(thunkAPI.getState());
    let script = {};

    referralToken && thunkAPI.dispatch(startReferralAccessing());
    if (isEducation) {
      const data = referralToken ? { education: id } : { education: id, ...parameters };
      script = referralToken
        ? await EducationService.getEducationByReferral(data, referralToken)
        : await EducationService.getEducationByPin(data);
    } else {
      const data = referralToken ? { script: id } : { script: id, ...parameters };
      script = referralToken
        ? await ScriptService.getScriptByReferral(data, referralToken)
        : await ScriptService.getScriptByPin(data);
    }

    // sessionStorage.setItem(AUTH_TOKEN, JSON.stringify(script.token));
    // !referralToken && thunkAPI.dispatch(allowAccessStatus());

    return { script, referralToken };
  }
);
