import Switch from "components/Switch";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsPreloaded } from "redux/reducers/ScriptsData";
import { selectPreloadedStatus } from "redux/selectors/Scripts";
import styled from "styled-components";
import { ReactComponent as ArrowIcon } from "../../assets/svg/icons/buttons/right-arrow.svg";
import LoadingHOC from "../../components/hoc/LoadingHOC";
import { ACCESS_STATUSES } from "../../constants/AppConstants";
import { formIsCompleted, getParametersFromUrl } from "../../helpers/functions/native-functions";
import { fetchFormDataById, fetchScriptByPin } from "../../redux/actions/AccessData";
import { setName, setPhone, setPin } from "../../redux/reducers/AccessData";
import {
  selectAccessFormData,
  selectAccessParameters,
  selectAccessStatus,
  selectIsFormLoading,
} from "../../redux/selectors/AccessData";
import NameInput from "./NameInput";
import PhoneInput from "./PhoneInput";
import PinCodeInput from "./PinCodeInput";

const AccessFormPage = () => {
  const dispatch = useDispatch();

  const { logo, parameters, decoration } = useSelector(selectAccessFormData);
  const { pin, name, phone } = useSelector(selectAccessParameters);
  const acessStatus = useSelector(selectAccessStatus);
  const isLoading = useSelector(selectIsFormLoading);

  const accessIsDeclined = acessStatus === ACCESS_STATUSES.declined;
  const formIsValid = formIsCompleted(pin, name, phone, parameters);

  const [imageIsLoad, setimageIsLoad] = useState(false);

  const isAllLoading = isLoading || !imageIsLoad;

  const handleLoadLogo = () => setimageIsLoad(true);

  const { id, isEducation } = getParametersFromUrl();

  useEffect(() => {
    dispatch(fetchFormDataById(id));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    logo === null && setimageIsLoad(true);
    // eslint-disable-next-line
  }, [logo]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(fetchScriptByPin({ id, isEducation }));
  };

  const handelSetPin = (value) => {
    dispatch(setPin(value));
  };
  const handelSetName = (value) => {
    dispatch(setName(value));
  };
  const handelSetPhone = (value) => {
    dispatch(setPhone(value));
  };

  const isPreloaded = useSelector(selectPreloadedStatus);
  const handleChange = () => {
    dispatch(setIsPreloaded(!isPreloaded));
  };

  // const showButton = formIsValid && !accessIsDeclined;
  const showButton = formIsValid;
  // const showButton = true;
  const showNameInput = parameters.name && !accessIsDeclined;
  const showPhoneInput = parameters.phone && !accessIsDeclined;

  return (
    <LoadingHOC loading={isAllLoading}>
      <FormWrapper onSubmit={handleSubmit} background={decoration.background}>
        {logo ? (
          <Logo src={logo} alt="Logo" onLoad={handleLoadLogo} onError={handleLoadLogo} />
        ) : (
          <LogoPlaceholder />
        )}

        <Title color={decoration.major}>QR-код успешно отсканирован</Title>

        <PinCodeInput
          pin={pin}
          setPin={handelSetPin}
          decoration={decoration}
          accessIsDeclined={accessIsDeclined}
        />

        {!isEducation && (
          <div>
            <SwitchContainer>
              <span>Предзагрузить сюжет</span>
              <Switch
                name="preload"
                checked={isPreloaded}
                onChange={handleChange}
                decoration={decoration}
              />
            </SwitchContainer>
            <SwitchDescription color={decoration.inactive}>
              {/* В случае отсутствия предзагрузки видео могут наблюдаться задержки */}
              Рекомендуется при слабом интернет соединении
            </SwitchDescription>
          </div>
        )}

        {showNameInput && <NameInput name={name} setName={handelSetName} decoration={decoration} />}

        {showPhoneInput && (
          <PhoneInput phone={phone} setPhone={handelSetPhone} decoration={decoration} />
        )}

        <Footer>Footer Footer Footer Footer</Footer>

        {showButton && (
          <Button background={decoration.accent}>
            <span>Далее</span>
            <ArrowIcon />
          </Button>
        )}
      </FormWrapper>
    </LoadingHOC>
  );
};

export default AccessFormPage;

const FormWrapper = styled.form`
  overflow: auto;
  width: 100%;
  height: 100%;

  padding: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  background: ${(props) => props.background || "#FFFFFF"};
`;

const Logo = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
`;

const LogoPlaceholder = styled.div`
  width: 80px;
  height: 80px;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & span {
    font-weight: 700;
  }
`;

const SwitchDescription = styled.p`
  text-align: center;
  font-size: 13px;
  color: ${(props) => props.color || "#000000"};
`;

const Title = styled.div`
  max-width: 200px;

  color: ${(props) => props.color || "#000000"};

  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.408px;
  text-align: center;
`;

const Button = styled.button`
  position: fixed;
  bottom: 0px;
  margin-bottom: 8px;

  box-sizing: border-box;
  border: none;
  outline: none;

  height: 53px;
  width: 155px;

  padding: 16px;
  border-radius: 20px;

  color: #ffffff;
  background: ${(props) => props.background || "#191E43"};

  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: -0.408px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  cursor: pointer;
`;

const Footer = styled.div`
  max-width: 100px;
  user-select: none;
  opacity: 0;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
`;
