import { createSlice } from "@reduxjs/toolkit";
import { ACCESS_DATA_CONFIG } from "../../config/AccessDataConfig";
import { ACCESS_STATUSES } from "../../constants/AppConstants";
import {
  checkScriptById,
  fetchFormDataById,
  fetchLogos,
  fetchScriptByPin,
} from "../actions/AccessData";

const initAccessData = { ...ACCESS_DATA_CONFIG };

export const accessDataSlice = createSlice({
  name: "analytics",
  initialState: initAccessData,
  reducers: {
    setFormData: (state, action) => {
      state.formData = action.payload;
    },

    setPin: (state, action) => {
      state.accessParameters.pin = action.payload;
    },
    setName: (state, action) => {
      state.accessParameters.name = action.payload;
    },
    setPhone: (state, action) => {
      state.accessParameters.phone = action.payload;
    },
    startReferralAccessing: (state, action) => {
      state.isReferralAccessLoading = true;
    },
    setScriptIsNotExist: (state, action) => {
      state.isScriptExist = false;
    },
    allowAccessStatus: (state, action) => {
      state.accessStatus = ACCESS_STATUSES.allowed;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormDataById.fulfilled, (state, action) => {
        state.formData = { ...state.formData, ...action.payload };
        state.isLoading = false;
      })
      .addCase(fetchFormDataById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchScriptByPin.fulfilled, (state, action) => {
        const { referralToken } = action.payload;
        if (!referralToken) state.accessStatus = ACCESS_STATUSES.allowed;
      })
      .addCase(fetchScriptByPin.rejected, (state, action) => {
        state.accessStatus = ACCESS_STATUSES.declined;
      })
      .addCase(checkScriptById.fulfilled, (state, action) => {
        state.isScriptExist = action.payload;
      })
      .addCase(fetchLogos.fulfilled, (state, action) => {
        const { logo, favicon, hdr } = action.payload;
        state.formData.logo = logo;
        state.favicon = favicon;
        state.hdr = hdr;
      });
  },
});

export const {
  setFormData,
  setPin,
  setName,
  setPhone,
  startReferralAccessing,
  setScriptIsNotExist,
  allowAccessStatus,
} = accessDataSlice.actions;

export default accessDataSlice.reducer;
