export const selectAccessFormData = (state) => state.accessData?.formData;
export const selectAccessParameters = (state) => state.accessData?.accessParameters;

export const selectAccessStatus = (state) => state.accessData?.accessStatus;

export const selectIsFormLoading = (state) => state.accessData?.isLoading;
export const selectReferralAccessStatus = (state) => state.accessData?.isReferralAccessLoading;

export const selectScriptExistStatus = (state) => state.accessData?.isScriptExist;
export const selectFavicon = (state) => state.accessData?.favicon;

export const selectHdr = (state) => state.accessData?.hdr;
