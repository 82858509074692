export const SCRIPTS_CONFIG = {
  markData: {
    image: null,
    mark: null,
    mark_count: 1,
  },
  without_mark: false,
  videos: [],
  models_3d: [],
  isLoading: false,
  isPreloaded: false,
  preloadedUrls: [],
  selectedScript: 0,
  token: null,
  type: null,
};
