import useWindowHeight from "hooks/useWindowHeight";
import { Redirect, Route, Switch } from "react-router-dom";
import EducationPage from "./EducationPage";

const ArEducationPage = () => {
  useWindowHeight();

  return (
    <Switch>
      <Route path={`/e/lesson/:lessonId?`} component={EducationPage} />
      <Redirect to={`/e/lesson/1`} />
    </Switch>
  );
};

export default ArEducationPage;
