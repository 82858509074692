import AccessData from "./AccessData";
import Analytics from "./Analytics";
import LessonReducer from "./Lesson.reducer";
import ScriptsData from "./ScriptsData";
import Video from "./Video";

const reducers = {
  scriptsData: ScriptsData,
  accessData: AccessData,
  video: Video,
  analytics: Analytics,
  lesson: LessonReducer,
};

export default reducers;
